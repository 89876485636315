import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useImmer } from "use-immer";
import { arrayMove } from "@dnd-kit/sortable";
import useMutateCategory from "../../hooks/useMutateCategory";
import useCategories from "../../hooks/useCategories";
import useGlobalParams from "../../hooks/useGlobalParams";
import { handleNetworkError } from "../../utils/api";
import { useDebouncedCallback } from "use-debounce";

import PLink from "../../common/Navigation/PLink";
import ConfirmationModal from "../../common/ConfirmationModal/ConfirmationModal";
import Sortable from "../../common/Sortable/Sortable";
import Form from 'react-bootstrap/Form';
import Button from "../../common/Button/Button";
import CategoryStatusDropdown from "./CategoryStatusDropdown";
import EditableField from "../../common/EditableField/EditableField";

import TrashIcon from "../../assets/icons/TrashIcon";
import { ReactComponent as ExternalLink } from "../../assets/icons_svg/external-link.svg";
import { ReactComponent as SettingsIcon } from "../../assets/icons_svg/gear.svg";

import './CategoriesList.scss';

export default function CategoriesList({ showToast }) {
  
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const [showCreate, setShowCreate] = useState(false);
  const [categoryOrder, setCategoryOrder] = useImmer(null);
  
  const [createName, setCreateName] = useState('');
  const [createdId, setCreatedId] = useState(null);
  const [createPending, setCreatePending] = useState(false);
  const [createErrors, setCreateErrors] = useState(false);
  const [createValidated, setCreateValidated] = useState(false);

  const addRef = useRef();
  const newItemRef = useRef();
  
  const { businessId } = useGlobalParams();
  const { createCategory, updateCategory, deleteCategory, bulkUpdateCategories } = useMutateCategory();
  const { data: categories } = useCategories({ businessId });

  useEffect(() => {
    const order = categories?.sort((a, b) => a.order_index - b.order_index).map(status => ({ id: status.id }));
    order && setCategoryOrder(order);
  }, [categories, setCategoryOrder]);

  useLayoutEffect(() => {
    if (showCreate) {
      const rect = addRef.current.getBoundingClientRect();

      if (rect.bottom > window.innerHeight || rect.top < 0) {
        addRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [showCreate]);

  useLayoutEffect(() => {
    if (createdId) {
      newItemRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [createdId]);

  const onEditSucces = () => {
    showToast('Category name updated')
  }
  
  const toggleAdd = () => {
    setShowCreate(true);
    setCreateName('');
    setCreateErrors(null);
    setCreateValidated(false);
  }

  const hideCreated = useDebouncedCallback(() => {
    setCreatedId(null);
  }, 3000);

  const onToggleArchive = id => {
    const category = categories.find(category => category.id === id);
    
    const data = {
      archived: !category.archived,
    }

    updateCategory.mutate({ data, id });
  }

  const handleCreate = e => {
    e.preventDefault();

    setCreatePending(true);
    setCreateErrors(false);
    setCreateValidated(false);

    const order_index = categories.reduce((max, category) => category.order_index > max ? category.order_index : max, 0) + 1;

    const data = {
      name        : createName.trim(),
      business_id : businessId,
      order_index
    }

    const onSuccess = item => {
      setShowCreate(false);
      showToast('New category created');

      setCreatedId(item.id);
      hideCreated.cancel();
      hideCreated();
    }

    const onSettled = () => {
      setCreatePending(false);
    }

    const onError = e => {
      handleNetworkError({ e, setErrors: setCreateErrors, setValidated: setCreateValidated });
    }

    createCategory.mutate({ data }, { onSuccess, onSettled, onError });
  }

  const onDeleteConfirm = () => {
    setShowDelete(false);
    setDeleteId(null);

    deleteCategory.mutate(deleteId);
    showToast('Category deleted from business');
  }

  const onDelete = id => {
    setShowDelete(true);
    setDeleteId(id);
  }

  const onChangeOrder = (activeIndex, overIndex) => {
    const lowestIndex = Math.min(activeIndex, overIndex);
    const highestIndex = Math.max(activeIndex, overIndex);

    const newOrder = arrayMove(categoryOrder, activeIndex, overIndex);

    const data = [];

    for (let i = lowestIndex; i <= highestIndex; ++i) {
      const item = categories.find(category => category.id === newOrder[i].id);
      data.push({ id: item.id, order_index: i });
    }

    bulkUpdateCategories.mutate({ data });
    setCategoryOrder(newOrder);
  }

  const renderItem = item => {
    const category = categories.find(category => item.id === category.id);

    // when deleting a status, the data might be insconsistent, delete if no status found
    if (!category) return;

    // const newlyCreated = createdStatusId === status.id;
    const newlyCreated = createdId === item.id;

    return (
      <Sortable.Item id={category.id} newlyCreated={newlyCreated} columnView scrollOnCreate>
        <div className="row-handle" ref={newItemRef}><Sortable.DragHandle /></div>
        <div className="row-name">
          <EditableField field='name' initialValue={category.name} id={category.id} onEditSucces={onEditSucces} mutation={useMutateCategory} updateName='updateCategorySync' />
        </div>
        <div className="row-leads-count">{ category.lead_count.toLocaleString() }</div>
        <div className="row-status"><CategoryStatusDropdown category={category} onToggleArchive={() => onToggleArchive(category.id)} /></div>
        <div className="row-settings"><PLink className='view-link' to={`/leads/settings/${category.id}`}><SettingsIcon /> Settings</PLink></div>
        <div className="row-view"><PLink className='view-link' to={`/leads/${category.id}`}><ExternalLink /> View</PLink></div>
        <div className="row-delete">
          <button className='delete-btn' onClick={() => onDelete(category.id)}><TrashIcon /> Remove</button>
        </div>
      </Sortable.Item>
    );
  }

  return (
    <div className="__categories-list">
      <div className="title-actions">
        <h2>Categories</h2>
        { !showCreate && <button className="main-action" onClick={toggleAdd}>＋ Add Category</button> }
      </div>

      { showCreate && (
          <div className="add-category" ref={addRef}>
          <Form onSubmit={handleCreate} noValidate validated={createValidated && !createErrors}>
            <div className="name-description">
              <Form.Group className="name">
                <Form.Label>Name</Form.Label>
                <Form.Control autoFocus value={createName} onChange={e => setCreateName(e.target.value)} isValid={createValidated && !createErrors?.name} isInvalid={createErrors?.name} />
                { createErrors?.name?.map(error => <Form.Control.Feedback key={error} type='invalid'>{ error }</Form.Control.Feedback> )}
              </Form.Group>

              <div className="actions">
                <Button disabled={createPending} className='cancel' type='button' onClick={() => setShowCreate(false)}>Cancel</Button>
                <Button loading={createPending} type='submit' disabled={createName.trim().length === 0}>Create</Button>
              </div>
            </div>

            { createErrors?.non_field_errors?.map(error => <div className="request-error" key={error}>{ error }</div> )}
          </Form>
        </div>
      )}

      <div className="categories-table">
        <div className="table-head">
          <div className="row-handle" />
          <div className="row-name">Name</div>
          <div className="row-leads-count">Total Leads</div>
          <div className="row-status">Status</div>
          <div className="row-settings" />
          <div className="row-view" />
          <div className="row-delete" />
        </div>
        { categoryOrder && (
          <Sortable items={categoryOrder} onChange={onChangeOrder} renderItem={renderItem} columnView />
        )}
      </div>

      <ConfirmationModal title='Delete Category' show={showDelete} inputConfirm onHide={() => setShowDelete(false)} onConfirm={onDeleteConfirm} confirmText='Delete'>
        <p><strong>Are you sure you want to delete this category?</strong></p>
        <p>Deleting this category will make the leads that belong to it inaccessible.</p>
      </ConfirmationModal>
    </div>
  );
}
