import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from 'use-debounce';
import { handleNetworkError } from "../../utils/api";
import useGlobalParams from "../../hooks/useGlobalParams";
import AppContext from "../../utils/AppContext";
import useMutateCategory from "../../hooks/useMutateCategory";
import useCategory from "../../hooks/useCategory";
import { isBusinessUser, isCategoryUser } from "../../utils/userUtils";

import LeadsMode from "../../common/Navigation/LeadsMode";
import LeadStatusManager from "./LeadStatusManager";
import CustomFieldsManager from "./CustomFieldsManager";
import StatusDropdown from "../Leads/StatusDropdown";
import LeadDistribution from "./LeadDistribution";
import ConfirmationModal from "../../common/ConfirmationModal/ConfirmationModal";
import LeadImportNotifications from "../../common/Leads/LeadImportNotifications";

import Form from 'react-bootstrap/Form';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Toast from "../../common/Toast/Toast";
import Button from "../../common/Button/Button";

import { ReactComponent as InfoIcon } from '../../assets/icons_svg/info.svg';

import './CategorySettings.scss';

const DEFAULT_SAVED_MESSAGE = 'Changes saved';

export default function CategorySettings() {
  const [state] = useContext(AppContext);
  const { categoryId, businessId } = useGlobalParams();
  const navigate = useNavigate();
  
  const [showToast, setShowToast] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [toastMessage, setToastMessage] = useState(DEFAULT_SAVED_MESSAGE);

  const [categoryName, setCategoryName] = useState(null);
  const [savingName, setSavingName] = useState(false);
  const [nameErrors, setNameErrors] = useState(null);
  const [nameValidated, setNameValidated] = useState(false);

  const [urlCopied, setUrlCopied] = useState(false);

  const { currentUserRole } = state;

  const { data: category, isLoading } = useCategory({ businessId, categoryId });
  const { updateCategory, deleteCategory } = useMutateCategory();

  // Lead Users cannot access the settings page:
  useEffect(() => {
    if (currentUserRole && !isCategoryUser(currentUserRole)) {
      navigate('/');
    }
  }, [currentUserRole, navigate]);


  useEffect(() => {
    if (category && categoryName === null) {
      setCategoryName(category.name)
    }
  }, [category, categoryName]);

  const renderLoading = () => <div>Loading...</div>

  const showSavedToast = (message = DEFAULT_SAVED_MESSAGE) => {
    hideSavedToast.cancel();
    setShowToast(true);
    setToastMessage(message);
    hideSavedToast();
  }

  const hideSavedToast = useDebouncedCallback(() => {
    setShowToast(false);
  }, [2000]);

  const handleRename = async e => {
    e.preventDefault();
    
    setNameErrors(null);
    setNameValidated(false);

    // if the name didn't change, don't do anything
    if (categoryName?.trim() === category.name) {
      return;
    }

    setSavingName(true);

    const onError = e => {
      handleNetworkError({ e, setErrors: setNameErrors, setValidated: setNameValidated });
    }

    const onSuccess = () => {
      showSavedToast();
      document.activeElement.blur();
    }

    const onSettled = () => {
      setSavingName(false);
    }

    updateCategory.mutate({ data: { name: categoryName.trim() }, id: categoryId }, { onError, onSuccess, onSettled });
  }

  const onChangeDefaultStatus = async id => {
    updateCategory.mutate({ data: { default_status_id: id }, id: categoryId });
  }

  const handleCopyWebhookURL = async e => {
    e.preventDefault();

    await navigator.clipboard.writeText(category.webhook.url);

    setUrlCopied(true);
    debounceHideUrlCopied();
  }

  const debounceHideUrlCopied = useDebouncedCallback(() => {
    setUrlCopied(false);
  }, 2000);

  const onDeleteCategory = () => {
    setShowDelete(true);
  }

  const onConfirmDelete = () => {
    deleteCategory.mutate(categoryId);
    navigate('/');
  }

  const renderStatusTooltip = props => (
    <Tooltip {...props} className="__tooltip">
      All incoming leads in this category will
      have this status assigned by default.
    </Tooltip>
  );

  const renderContent = () => (
    <div className="content">
      <h2>General Settings</h2>
      <div className="section">
        <Form noValidate validated={nameValidated && !nameErrors} className="single-field" onSubmit={handleRename}>
          <Form.Label>Category name</Form.Label>
          <Form.Group className="input-button">
            <Form.Control value={categoryName || ''} onChange={e => setCategoryName(e.target.value)} isValid={nameValidated && !nameErrors?.name} isInvalid={nameErrors?.name} />
            <Button type='submit' loading={savingName} disabled={categoryName?.trim().length === 0}>Rename</Button>
            { nameErrors?.name?.map(error => <Form.Control.Feedback key={error} type="invalid">{ error }</Form.Control.Feedback> )}
            { nameErrors?.non_field_errors?.map(error => <div className="errors" key={error}>{ error }</div> )}
          </Form.Group>

        </Form>
        <div className="default-status-info">
          <div className="default-status">
            <div className="label">Default Status</div>
            <StatusDropdown categoryId={categoryId} selectedId={category.default_status_id} onChange={onChangeDefaultStatus} />
          </div>
          <OverlayTrigger placement="right" delay={{ show: 200, hide: 400 }} overlay={renderStatusTooltip}>
            <InfoIcon className="info-icon" />
          </OverlayTrigger>
        </div>
      </div>

      <h2>Integrations</h2>
      <div className="section">
        <Form className="single-field" onSubmit={handleCopyWebhookURL}>
          <div className="label-url-copied">
            <Form.Label>Webhook URL</Form.Label>
            { urlCopied && <div className="url-copied">URL copied to clipboard</div> }
          </div>
          <div className="input-button">
            <Form.Control value={category.webhook.url} readOnly />
            <button type='submit'>{ urlCopied ? 'Copied' : 'Copy URL' }</button>
          </div>
          <div className="field-description">
            Precious Leads can be integrated with most platforms such as Facebook Ads and Zapier using <strong>webhooks</strong>. Visit our <a href='#GUIDE'>Integration Guide</a> for more details.
          </div>
        </Form>
      </div>

      <LeadDistribution categoryId={categoryId} />
      <LeadStatusManager categoryId={categoryId} showSavedToast={showSavedToast} />
      <CustomFieldsManager categoryId={categoryId} showSavedToast={showSavedToast} />

      { isBusinessUser(currentUserRole) && (
        <section className='danger-zone'>
          <h2>Danger Zone</h2>

          <div className='delete-warning'>
            <div className='warning-message'>If you delete this category, all leads that belong to this category will be inaccessible. Make sure you’re certain.</div>
            <Button className='delete' onClick={onDeleteCategory}>Delete Category</Button>
          </div>
        </section>
      )}

      <ConfirmationModal title='Delete Category' inputConfirm show={showDelete} onHide={() => setShowDelete(false)} onConfirm={onConfirmDelete} confirmText='Delete'>
        <p>Are you sure you want to delete this category?</p>
        <p>You will not be able to access any leads associated to this category</p>
      </ConfirmationModal>

      <Toast show={showToast} hideClose>{ toastMessage }</Toast>
    </div>
  )

  return (
    <section className='__category-settings'>
      <LeadImportNotifications settingsPage />
      <LeadsMode />
      { isLoading ? renderLoading() : renderContent() }
    </section>
  );
}
