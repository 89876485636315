import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "../../utils/AppContext";
import useGlobalParams from "../../hooks/useGlobalParams";

import useMutateBusiness from "../../hooks/useMutateBusiness";
import useBusinesses from "../../hooks/useBusinesses";
import useToast from "../../hooks/useToast";
import { handleNetworkError } from "../../utils/api";
import { isAdminUser } from "../../utils/userUtils";

import PLink from "../../common/Navigation/PLink";
import CategoriesList from "./CategoriesList";
import ConfirmationModal from "../../common/ConfirmationModal/ConfirmationModal";
import Form from 'react-bootstrap/Form';
import Button from "../../common/Button/Button";
import Toast from "../../common/Toast/Toast";

import './BusinessSettings.scss';

export default function BusinessSettings(props) {
  const [state] = useContext(AppContext);
  const navigate = useNavigate();
  const { toast, showToast } = useToast();
  const { organizationId, businessId } = useGlobalParams();
  
  const [businessName, setBusinessName] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [nameErrors, setNameErrors] = useState(null);
  const [nameValidated, setNameValidated] = useState(false);
  const [namePending, setNamePending] = useState(false);
  
  const { data: businesses } = useBusinesses({ organization_id: organizationId });  
  const { updateBusiness, deleteBusiness } = useMutateBusiness({ id: businessId });
  
  const { currentUserRole } = state;
  const business = businesses?.find(business => business.id === businessId);

  // Only Admins can see the business settings page:
  useEffect(() => {
    if (currentUserRole && !isAdminUser(currentUserRole)) {
      navigate('/');
    }
  }, [currentUserRole, navigate]);

  useEffect(() => {
    if (business && businessName === null) {
      setBusinessName(business.name);
    }
  }, [business, businessName]);

  const handleRename = e => {
    e.preventDefault();

    setNameErrors(null);

    if (businessName.trim() === business.name) {
      setNameValidated(false);
      return;
    }

    setNamePending(true);
    setNameValidated(false);
    setBusinessName(businessName.trim())

    const onSuccess = () => {
      showToast('Changes saved');
      document.activeElement.blur();
    }

    const onError = e => {
      handleNetworkError({ e, setErrors: setNameErrors, setValidated: setNameValidated });
    }

    const onSettled = () => {
      setNamePending(false);
    }

    updateBusiness.mutate({ data: { name: businessName.trim() }, id: businessId }, { onSuccess, onError, onSettled });
  }

  const onDeleteBusiness = e => {
    setShowDelete(true);
  }

  const onConfirmDelete = () => {
    deleteBusiness.mutate(businessId);
    navigate('/');
  }

  if (!currentUserRole || !businesses) {
    return;
  }

  const renderContent = () => (
    <div className="content">

      <h2>Business Settings</h2>

      <div className="section">
        <Form onSubmit={handleRename} className="single-field">
          <Form.Label>Business name</Form.Label>
          <Form.Group className="input-button">
            <Form.Control value={businessName || ''} onChange={e => setBusinessName(e.target.value)} isValid={nameValidated && !nameErrors?.name} isInvalid={nameErrors?.name} />
            <Button type='submit' loading={namePending} disabled={businessName?.trim().length === 0}>Rename</Button>
            { nameErrors?.name?.map(error => <Form.Control.Feedback key={error} type="invalid">{ error }</Form.Control.Feedback> )}
            { nameErrors?.non_field_errors?.map(error => <div className="errors" key={error}>{ error }</div> )}
            </Form.Group>
        </Form>
      </div>

      <CategoriesList showToast={showToast} />

      <h2>Users & Roles</h2>
      
      <div className="section">
        <p className="user-roles">To manage users and roles, go to your <PLink to='/account'>Account Settings.</PLink></p>
      </div>

      <section className='danger-zone'>
        <h2>Danger Zone</h2>

        <div className='delete-business'>
          <div className='warning-message'>If you delete this business, all leads and categories will be inaccessible to all users. Make sure you’re certain.</div>
          <Button className='delete' onClick={onDeleteBusiness}>Delete Business</Button>
        </div>
      </section>

      <Toast toast={toast} hideClose />

      <ConfirmationModal title='Delete Business' inputConfirm show={showDelete} onHide={() => setShowDelete(false)} onConfirm={onConfirmDelete} confirmText='Delete'>
        <p>Are you sure you want to delete this business?</p>
        <p>You will not be able to access any categories or leads associated to this business</p>
      </ConfirmationModal>
    </div>
  );

  return (
    <section className='__business-settings'>
      { renderContent() }
    </section>
  )
}
