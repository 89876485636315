import { useLocation, useNavigate, useParams } from "react-router-dom";
import PLink from "../../common/Navigation/PLink";
import { USER_ROLES, userFullName } from "../../utils/userUtils";

import useGlobalParams from "../../hooks/useGlobalParams";
import useUserRoles from "../../hooks/useUserRoles";
import useUsers from "../../hooks/useUsers";
import useBusinesses from "../../hooks/useBusinesses";
import useMutateRole from "../../hooks/useMutateRole";

import RoleCategories from "./RoleCategories";

import Button from "../../common/Button/Button";
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';

import './UserDetails.scss';

export default function UserDetails() {
  const { organizationId } = useGlobalParams();
  const { roleId: paramRoleId } = useParams();
  const location = useLocation();
  const roleId = parseInt(paramRoleId);
  const navigate = useNavigate();

  const { data: users } = useUsers({ organizationId });
  const { data: userRoles } = useUserRoles({ organizationId });
  const { data: businesses } = useBusinesses({ organization_id: organizationId, staleTime: 10000 });

  const { updateUserRole, deleteUserRole } = useMutateRole({ organizationId });

  if (!users || !userRoles || !businesses) {
    return;
  }

  const role = userRoles.find(role => role.id === roleId);
  const user = users.find(user => user.id === role?.user_id);

  const changeRole = newRole => {
    const data = {
      role: newRole
    }

    updateUserRole.mutate({ data, id: role.id });
  }

  const onDeleteRole = () => {
    const onSuccess = () => {
      navigate({ pathname: '/users', search: location.search }, { replace: true });
    }

    deleteUserRole.mutate(roleId, { onSuccess });
  }

  if (!role) {
    return;
  }

  return (
    <section className='__user-details'>
      <p><PLink to='/users'>BACK</PLink></p>
      <h3>{ userFullName(user) }</h3>

      { !user && (
        <>
          <div>Invite URL:</div>
          <Form.Control value={`${window.location.origin}/invite?token=${role.invitation_token}`} readOnly />
        </>
      )}

      <Dropdown>
        <Dropdown.Toggle>{ USER_ROLES[role.role] }</Dropdown.Toggle>
        <Dropdown.Menu>
          { Object.entries(USER_ROLES).map(([key, value]) => (
            <Dropdown.Item key={key} active={role.role === key} onClick={() => changeRole(key)}>{ value }</Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>

      <div>
        { businesses.map(business => (
          <div key={business.id} className="my-4">
            <h3><Form.Check checked={role.business_ids.includes(business.id)} label={business.name} /></h3>
            <RoleCategories businessId={business.id} role={role} />
          </div>
        ))}
      </div>

      <Button variant='primary' onClick={onDeleteRole}>DELETE ROLE</Button>
    </section>
  )
}
