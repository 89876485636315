import { useMutation, useQueryClient } from "@tanstack/react-query"
import { produce } from "immer";
import { api } from "../utils/api";

const apiCreate = ({ data }) => api.post('/user-roles/invitations/create/', { ...data }).then(res => res.data);
const apiUpdate = ({ data, id }) => api.put(`/user-roles/${id}/`, { ...data }).then(res => res.data);
const apiDelete = id => api.delete(`/user-roles/${id}`).then(res => res.data);

const deleteItem = (draft, id) => {
  const index = draft.findIndex(item => item.id === id);

  if (index !== -1) {
    draft.splice(index, 1);
  }
}

export default function useMutateRole({ organizationId }) {
  const queryClient = useQueryClient();

  const onCreate = requestData => {
    queryClient.setQueriesData(['user-roles', organizationId], data => produce(data, draft => {
      draft.push(requestData);
    }));
  };

  const onUpdate = ({ data: requestData, id }) => {
    queryClient.cancelQueries(['user-roles']);
    
    queryClient.setQueriesData(['user-roles', organizationId], data => produce(data, draft => {
      const index = data.findIndex(item => item.id === id);

      if (index !== -1) {
        draft[index] = { ...data[index], ...requestData }
      }
    }));
  }

  const onDelete = id => {
    queryClient.cancelQueries(['user-roles']);

    queryClient.setQueriesData(['user-roles', organizationId], data => produce(data, draft => {
      deleteItem(draft, id);
    }));
  }

  return {
    createInvitation : useMutation({ mutationFn: apiCreate, onSuccess: onCreate }),
    updateUserRole   : useMutation({ mutationFn: apiUpdate, onMutate: onUpdate }),
    deleteUserRole   : useMutation({ mutationFn: apiDelete, onMutate: onDelete }),
  }
}
