import React, { forwardRef, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useMatch, useSearchParams } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import classNames from "classnames";

import AppContext from "../../utils/AppContext";
import useCategories from "../../hooks/useCategories";
import useOrganizations from "../../hooks/useOrganizations";
import useBusinesses from "../../hooks/useBusinesses";
import useUserRoles from "../../hooks/useUserRoles";
import { userColor, userFullName, userInitials } from "../../utils/userUtils";

import Dropdown from 'react-bootstrap/Dropdown';
import PLink from "./PLink";
import PNavLink from "./PNavLink";
import BusinessDropdown from "./BusinessDropdown";

import ChevronIcon from "../../assets/icons/ChevronIcon";
import ChartIcon from "../../assets/icons/ChartIcon";
import WrenchIcon from "../../assets/icons/WrenchIcon";
import ChevronThickIcon from "../../assets/icons/ChevronThickIcon";
import LeadsIcon from "../../assets/icons/LeadsIcon";
import UserIcon from "../../assets/icons/UserIcon";

import { ReactComponent as ArchiveIcon } from "../../assets/icons_svg/archive.svg";

import './Navigation.scss';

const NAV_LINKS = [
  { route: '/leads',     icon: LeadsIcon, label: "Leads" },
];

const ADMIN_LINKS = [
  { route: '/reporting', icon: ChartIcon,  label: "Reports" },
  { route: '/users',     icon: UserIcon,   label: "User Management",   shortLabel: "Users" },
  { route: '/settings',  icon: WrenchIcon, label: "Business Settings", shortLabel: "Business" },
];

export default function Navigation() {

  const [state, dispatch] = useContext(AppContext);
  const { currentUser, localSettings } = state;

  const [searchParams, setSearchParams] = useSearchParams();

  const organizationId = parseInt(searchParams?.get('organization_id', 10)) || null;
  const businessId = parseInt(searchParams?.get('business_id', 10)) || null;
  
  const match = useMatch('/leads/*');

  const [collapsed, setCollapsed] = useState(() => localSettings.navigation_collapsed);
  const [viewArchived, setViewArchived] = useState(() => localSettings.view_archived);
  const [archivedToggled, setArchivedToggled] = useState(false);

  const { data: categories } = useCategories({ businessId, staleTime: 10000 });
  const { data: organizations } = useOrganizations({ staleTime: 10000 });
  const { data: businesses } = useBusinesses({ organization_id: organizationId, staleTime: 10000 });
  const { data: userRoles } = useUserRoles({ organizationId, staleTime: 10000 });

  const organization = organizations?.find(organization => organization.id === organizationId);
  const business = businesses?.find(business => business.id === businessId);

  const { default_organization_id: defaultOrganizationId, default_business_id: defaultBusinessId } = localSettings;
  
  const navigationRef = useRef();
  const archivedRef = useRef();

  const userRole = userRoles?.find(role => role.user_id === currentUser.id);
  const isAdmin = userRole?.role === 'admin';

  useEffect(() => {
    if (userRole) {
      dispatch({ type: 'currentUserRole/set', payload: userRole.role });
    }
  }, [userRole, dispatch]);

  // Check if the user has an organization and businessId on the query string.
  // If the user has an id set, make sure it can find it inside its fetched organizations and businesses
  // if not, then set the one from localSettings, and make sure it can find said organization and business
  // If not, set the first item found (item[0]). And replace the query string param
  useEffect(() => {
    if (organizations && (!organizationId || !organizations.find(organization => organization.id === organizationId))) {
      const organizationId = (defaultOrganizationId && organizations.find(organization => organization.id === defaultOrganizationId)?.id) || organizations[0]?.id;
        
      setSearchParams(prev => (
        new URLSearchParams({ ...Object.fromEntries(prev.entries()), organization_id: organizationId })
      ));
    }
  }, [organizations, organizationId, defaultOrganizationId, setSearchParams]);

  useEffect(() => {
    if (businesses && (!businessId || !businesses.find(business => business.id === businessId))) {
      const businessId = (defaultBusinessId && businesses.find(business => business.id === defaultBusinessId)?.id) || businesses[0]?.id;

      setSearchParams(prev => (
        new URLSearchParams({ ...Object.fromEntries(prev.entries()), business_id: businessId })
      ));
    }
  }, [businesses, businessId, defaultBusinessId, setSearchParams]);

  // When organization or business Id change to a not-null value
  // (null will happen on the very first refresh, causing a race condition),
  // set the localSettings to the query_string organizationId:
  useEffect(() => {
    organizationId && dispatch({ type: 'localSettings/edit', payload: { setting: 'default_organization_id', value: organizationId } });
  }, [organizationId, dispatch]);

  useEffect(() => {
   businessId && dispatch({ type: 'localSettings/edit', payload: { setting: 'default_business_id', value: businessId } });
  }, [businessId, dispatch]);

  useEffect(() => {
    viewArchived?.[businessId] && archivedToggled && archivedRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [viewArchived, archivedToggled, businessId]);

  const sortedCategories = useMemo(() => {
    return categories?.filter(category => !category.archived).sort((a, b) => a.order_index - b.order_index);
  }, [categories]);

  const archivedCategories = useMemo(() => {
    return categories?.filter(category => category.archived).sort((a, b) => a.order_index - b.order_index);
  }, [categories]);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
    dispatch({ type: 'localSettings/edit', payload: { setting: 'navigation_collapsed', value: !collapsed } });
  }

  const toggleViewArchived = () => {
    let value;

    !archivedToggled && setArchivedToggled(true);

    if (!viewArchived || !viewArchived?.[businessId]) {
      value = { [businessId]: true };
    }
    else {
      value = { ...viewArchived, [businessId]: undefined };
    }

    setViewArchived(value);
    dispatch({ type: 'localSettings/edit', payload: { setting: 'view_archived', value } });
  }

  const renderCategories = () => (
    <div className={classNames('main-route main-categories', { 'active-lead': match})} key='leads'>
      <PLink className={classNames('main-link', { active: match })} to='/leads'>
        <LeadsIcon className='link-icon' />
        <span>Leads</span>
      </PLink>
      <div className="categories">
        { sortedCategories?.map(category => (
          <PNavLink key={category.id} className="sub-link" to={`/leads/${category.id}`}>{ category.name }</PNavLink>
        ))}
        { archivedCategories?.length > 0 && (
          <button className="view-archived" onClick={toggleViewArchived} ref={archivedRef}>
            <ArchiveIcon className="archive-icon" />
            <span className="archived-text">Archived</span>
            <ChevronIcon className={classNames("archive-chevron", { flipped: viewArchived?.[businessId]})} />
          </button>
        )}
        { viewArchived?.[businessId] && archivedCategories?.map(category => (
          <PNavLink key={category.id} className="sub-link" to={`/leads/${category.id}`}>{ category.name }</PNavLink>
        ))} 
        { process.env.REACT_APP_SHOW_ALL_LEADS && <PNavLink className="sub-link view-all" to='/leads/'>All Leads</PNavLink> }
      </div>
    </div>
  );

  const renderCategoriesMenu = () => (
    <Dropdown drop='end' key='leads-dropdown' className='leads-dropdown'>
      <Dropdown.Toggle as={CategoriesToggle} active={match}>
        <LeadsIcon className='link-icon' />
        <span>Leads</span>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <div className='categories-title'>Categories</div>
        { sortedCategories?.map(category => (
          <Dropdown.Item as={PNavLink} key={category.id} to={`/leads/${category.id}`}>{ category.name }</Dropdown.Item>
        ))}

        { archivedCategories?.length > 0 && (
          <>
            <Dropdown.Divider />
            <div className='categories-title'>Archived</div>
            { archivedCategories?.map(category => (
              <Dropdown.Item as={PNavLink} key={category.id} to={`/leads/${category.id}`}>{ category.name }</Dropdown.Item>
            ))}
          </>
        )}

        { process.env.REACT_APP_SHOW_ALL_LEADS && (
          <>
            <Dropdown.Divider />
            <Dropdown.Item as={PNavLink} className="view-all" to='/leads/'>View All Leads</Dropdown.Item>
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );

  const renderIcon = icon => {
    const Icon = icon;
    return <Icon className='link-icon' />;
  }

  const renderLink = link => (
    <PNavLink className="main-route" key={link.route} to={link.route}>
      { renderIcon(link.icon) }
      <span>{ collapsed ? (link.shortLabel || link.label) : link.label }</span>
    </PNavLink>
  );

  return (
    <CSSTransition classNames='collapsed' nodeRef={navigationRef} appear in={collapsed} timeout={500}>
      <section className={classNames('__navigation', { collapsed })} ref={navigationRef}>
        <button className='toggle-navigation' onClick={toggleCollapsed}><ChevronThickIcon /></button>
        
        <div className='inner-container'>
          <div className='overlay-gradient' />
          <h1><PLink to='/'>Precious Leads</PLink></h1>

          <BusinessDropdown organizationId={organizationId} businesses={businesses} selected={business} />

          <nav className="page-navigation">
            { NAV_LINKS.map(link => (
              link.route === '/leads'
              ? (collapsed ? renderCategoriesMenu() : renderCategories())
              : renderLink(link)
            ))}

            { isAdmin && ADMIN_LINKS.map(link => renderLink(link)) }
          </nav>

          <PLink className="user-settings" to='/account'>
            <div className="profile-picture" style={{ backgroundColor: userColor(currentUser).code }}>{ userInitials(currentUser) }</div>
            <div className="user-name-account">
              <div className="user-name">{ userFullName(currentUser) }</div>
              { organizations?.length > 1 && <div className="user-account">{ organization?.name }</div> }
            </div>
            <ChevronIcon className='expand' />
          </PLink>
        </div>
        
        <div className='toggle-area' />
      </section>
    </CSSTransition>
  );
}

const CategoriesToggle = forwardRef(({ children, onClick, active, ...props }, ref) => (
  <div role='button' onClick={onClick} ref={ref} {...props} className={classNames('main-route', { active })}>
    { children }
  </div>
));
