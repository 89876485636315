import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AppContext from "../../utils/AppContext";
import i18n from "i18next";
import { api, resetAccessToken } from "../../utils/api";
import axios from "axios";

import useOrganizations from "../../hooks/useOrganizations";
import useMutateBusiness from "../../hooks/useMutateBusiness";
import useGlobalParams from "../../hooks/useGlobalParams";

import { Trans as T } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import PasswordInput from "../../common/PasswordInput/PasswordInput";

export default function Account(props) {
  const [state, dispatch] = useContext(AppContext);
  const [language, setlanguage] = useState(() => i18n.language);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const [passwordErrors, setPasswordErrors] = useState(null);
  const [passwordValidated, setPasswordValidated] = useState(false);

  const [businessName, setBusinessName] = useState('');

  const { colorTheme, currentUser } = state;

  const navigate = useNavigate();

  const { organizationId } = useGlobalParams();
  const { data: organizations } = useOrganizations({ staleTime: 10000 });

  const { createBusiness } = useMutateBusiness();

  const changeLanguage = e => {
    const lang = e.target.value;

    setlanguage(lang);
    localStorage.setItem('pl__language', lang);
    i18n.changeLanguage(lang);
  }

  const changeOrganization = e => {
    const id = e.target.value;
    navigate({ pathname: '/account', search: `organization_id=${id}` })
  }

  const changeTheme = e => {
    dispatch({ type: 'colorTheme/set', payload: e.target.value });
  }

  const changePassword = async e => {
    e.preventDefault();
    setPasswordErrors(null);
    
    try {
      await api.post('/change-password/', {
        old_password: oldPassword,
        new_password: newPassword,
      });

      const { data: loginData } = await axios.post(process.env.REACT_APP_API_URL + '/auth/token/', {
        client_id  : process.env.REACT_APP_API_CLIENT_ID,
        username   : currentUser.email,
        password   : newPassword,
        grant_type : 'password',
      });
      
      resetAccessToken(loginData.access_token);
      dispatch({ type: 'accessToken/set', payload: loginData.access_token });
      dispatch({ type: 'resetToken/set', payload: loginData.reset_token });

      alert("Password changed succesfully!");
      setOldPassword('');
      setNewPassword('');
      setPasswordValidated(false);
    }
    catch (error) {
      setPasswordErrors(error.response.data);
      setPasswordValidated(true);
    }
  }

  const onCreateBusiness = e => {
    e.preventDefault();

    const data = {
      name            : businessName,
      organization_id : organizationId,
    };

    createBusiness.mutate({ data }, { onSuccess: () => alert("LISTO!")});
  }

  return (
    <div className="m-4">
      <h1>Account Details</h1>
      <p><Link to='/logout'>Logout</Link></p>

      <Form.Group className='language-select'>
        <Form.Label><T>Language</T></Form.Label>
        <Form.Select value={language} onChange={changeLanguage}>
          <option value="en">English</option>
          <option value="es">Español</option>
        </Form.Select>
      </Form.Group>

      <Form.Group className='language-select my-4'>
        <Form.Label><T>Organization</T></Form.Label>
        <Form.Select value={organizationId} onChange={changeOrganization}>
          { organizations?.map(organization => (
            <option key={organization.id} disabled={organization.id === organizationId} value={organization.id}>{ organization.name }</option>
          ))}
        </Form.Select>
      </Form.Group>

      <Form.Group className='language-select'>
        <Form.Label><T>Theme</T></Form.Label>
        <Form.Select value={colorTheme} onChange={changeTheme}>
          <option value="light">Light</option>
          <option value="dark">Dark</option>
          <option value="auto">Auto (OS default)</option>
        </Form.Select>
      </Form.Group>

      <h3 className="mt-5"><T>Change Password</T></h3>
      <Form noValidate validated={passwordValidated && !passwordErrors} onSubmit={changePassword}>
        <Form.Group className='language-select my-4'>
          <Form.Label><T>Old Password</T></Form.Label>
          <PasswordInput value={oldPassword} onChange={e => setOldPassword(e.target.value)} isInvalid={passwordErrors?.old_password} isValid={passwordValidated && !passwordErrors?.old_password} feedback={ passwordErrors?.old_password?.map(error => <Form.Control.Feedback type="invalid" key={error}><T>{ error }</T></Form.Control.Feedback>) } />
        </Form.Group>

        <Form.Group className='language-select my-4'>
          <Form.Label className="mt-2"><T>New Password</T></Form.Label>
          <PasswordInput value={newPassword} onChange={e => setNewPassword(e.target.value)} isInvalid={passwordErrors?.new_password} isValid={passwordValidated && !passwordErrors?.new_password} feedback={ passwordErrors?.new_password?.map(error => <Form.Control.Feedback type="invalid" key={error}><T>{ error }</T></Form.Control.Feedback>) } />
        </Form.Group>

        <Button type="submit" disabled={!oldPassword.trim() || !newPassword.trim()}>Change</Button>
      </Form>

      <h3 className="mt-5">Create New Business</h3>
      <Form noValidate onSubmit={onCreateBusiness}>
        <Form.Group className='language-select my-4'>
          <Form.Label>Name</Form.Label>
          <Form.Control value={businessName} onChange={e => setBusinessName(e.target.value)} />
        </Form.Group>
        <Button type="submit" disabled={!businessName.trim()}>Create</Button>
      </Form>
    </div>
  )
}
