import Modal from 'react-bootstrap/Modal';
import Button from '../Button/Button';
import useGlobalParams from '../../hooks/useGlobalParams';
import useCategory from '../../hooks/useCategory';
import useMutateImportTask from '../../hooks/useMutateImportTask';

import Form from 'react-bootstrap/Form';
import LEAD_FIELDS, { FIELD_MAX_LENGTH, IMPORT_FIELDS, NAME_FIELDS } from '../../utils/leadFields';

import './ReviewLeadsModal.scss';
import { useEffect, useState } from 'react';
import { useImmer } from 'use-immer';
import useMutateLead from '../../hooks/useMutateLead';

import { ReactComponent as CheckIcon } from '../../assets/icons_svg/check-mark-outline.svg';
import { ReactComponent as XIcon } from '../../assets/icons_svg/x-mark-circle-lined.svg';
import classNames from 'classnames';

export default function ReviewLeadsModal({ taskId, show, onHide }) {
  const { businessId, categoryId } = useGlobalParams();

  const { data: category } = useCategory({ businessId, categoryId });
  const { markImportAsReadAsync } = useMutateImportTask({ categoryId });
  const { insertMutation } = useMutateLead();

  const [step, setStep] = useState('summary');
  const [leads, setLeads] = useImmer();
  const [checkedLeads, setCheckedLeads] = useImmer();
  const [focusedItem, setFocusedItem] = useState(null);

  const { unchecked_import_tasks } = category || [];

  const task = unchecked_import_tasks?.find(task => task.id === taskId);

  // if leads are not set, set them
  useEffect(() => {
    if (show && task) {
      setStep('summary');
      setLeads(task.errors);
      setCheckedLeads(new Array(task.errors.length).fill(false));
      setFocusedItem(null);
    }
  }, [show, task, setLeads, setCheckedLeads]);

  if (!leads) {
    return;
  }

  const addCheckedLeads = () => {
    checkedLeads.forEach((lead, index) => {
      lead === true && addLead(index);
    })
  }

  const addLead = index => {
    setCheckedLeads(draft => {
      draft[index] = 'processing';
    });

    const onSuccess = () => {
      console.log('ADDED!', index)
      setCheckedLeads(draft => {
        draft[index] = 'added';
      });  
    }

    const onError = e => {
      // console.log('e:', e);
      console.log('index:', index)

      setCheckedLeads(draft => {
        draft[index] = false;
      });
    }

    const data = {
      ...leads[index].item,
      category_id: categoryId,
    }

    // insertMutation.mutate({ data }, { onSuccess, onError });

    insertMutation.mutateAsync({ data }).then(a => {
      onSuccess();
    }).catch(e => {
      onError(e);
    });
  }

  const onSubmit = () => {
    markImportAsReadAsync.mutate({ id: taskId });
    onHide();
  }

  const renderSummary = () => (
    <div>
      <p>Your import has completed but we encountered some errors:</p>

      <div className='summary'>
        { task?.imported_leads > 0 && (
          <p className='success'><CheckIcon /> <strong>{task?.imported_leads.toLocaleString()} lead</strong>{task?.imported_leads > 1 ? <><strong>s</strong> were</> : ' was'} imported successfully.</p>
        )}
        <p className='error'><XIcon viewBox='2 2 20 20' /> <strong>{task?.leads_with_errors.toLocaleString()} lead</strong>{task?.imported_leads > 1 ? <><strong>s</strong> have</> : ' has'} errors and could not be imported.</p>
      </div>

      <p><em>Would you like to fix the leads with errors or discard them?</em></p>
    </div>
  )

  const renderTable = () => (
    <div className='leads'>
      <div className='table-head'>
        <div className='checkbox' />
        { Object.keys(leads[0].item).map(key => (
          <div key={key} className='input-field'>{ IMPORT_FIELDS.find(field => field === key) ? (LEAD_FIELDS[key]?.label || NAME_FIELDS[key]) : key }</div>
        ))}
      </div>
      <div className='table-body'>
        {leads.map((lead, index) => checkedLeads[index] !== 'deleted' && (
          <div className='table-row' key={index}>
            <div className='checkbox'>
              { checkedLeads[index] === 'added'
              ? <div>Added</div>
              : <div className='checkbox'>
                  <Form.Check checked={checkedLeads[index]} onChange={e => setCheckedLeads(draft => { draft[index] = e.target.checked })} />
                </div>
              }
            </div>
            { Object.entries(lead.item).map(([key, value]) => (
              <div key={key} className='input-field'>
                { checkedLeads[index] === 'added'
                ? <div className='input-completed'>{ value }</div>
                : <>
                    <Form.Control onFocus={() => setFocusedItem({ index, key })} onBlur={() => setFocusedItem(null) } maxLength={FIELD_MAX_LENGTH[key] || null} type={key === 'email' ? 'email' : 'text'} value={value || ''} isInvalid={checkedLeads[index] !== 'added' && lead.errors[key]?.length > 0} onChange={e => setLeads(draft => { draft[index].item[key] = e.target.value; draft[index].errors[key] = null; })} disabled={['added', 'processing'].includes(checkedLeads[index])} />
                    { focusedItem?.index === index && focusedItem?.key === key && lead.errors[key]?.map(error => <Form.Control.Feedback key={error} type='invalid'>{ error }</Form.Control.Feedback>) }
                  </>
                }
              </div>
            )) }
          </div>
        ))}
      </div>
    </div>
  )

  return (
    <Modal show={show} onHide={onHide} className={classNames('__modal __review-leads-modal', `__review-leads-modal-${step}`)} size={ step === 'table' ? 'xl' : 'md' } centered scrollable>
      <Modal.Header><h2>Review Import</h2></Modal.Header>
      <Modal.Body>
        { step === 'summary' ? renderSummary() : renderTable() }
      </Modal.Body>
      <Modal.Footer>
        { step === 'summary'
        ? <>
            <Button className='cancel' onClick={onSubmit}>Discard leads</Button>
            <Button className='confirm' onClick={() => setStep('table')}>Review & Fix</Button>
          </>
        : <Button onClick={addCheckedLeads}>DONE</Button>
        }
      </Modal.Footer>
    </Modal>
  );
}
