import { IS_DEV } from "./envSettings";
import USER_COLORS from "./userColors";
import runes from "runes";

export const USER_ROLES = {
  admin    : "Organization Admin",
  business : "Business Manager",
  category : "Category Manager",
  lead     : "Lead Manager",
}

export const userColor = user => {
  if (!user) {
    return {
      name : 'gainsboro',
      code: '#dcdcdc',
    }
  }

  const colorsLength = USER_COLORS.length;

  // square of user.id is used since USER_COLORS are ordered in similarity.
  // This helps when two user_ids are too close:
  return USER_COLORS[(user.id * user.id) % colorsLength];
}

export const userInitials = user => {
  return (runes.substr(user?.first_name || '', 0, 1) + runes.substr(user?.last_name || '', 0, 1)) || '';
}

export const userFullName = user => {
  const firstName = user?.first_name || '';
  const lastName = user?.last_name || '';

  let name = '';

  if (!lastName) {
    name = firstName;
  }
  else if (!firstName) {
    name = lastName;
  }
  else {
    name = firstName + ' ' + lastName;
  }

  if (IS_DEV && localStorage.getItem('pl__dev_show_user_id') && user) {
    return name + ` (${user.id})`;
  }

  return name;
}

export const isAdminUser = role => {
  return (role === 'admin');
}

export const isBusinessUser = role => {
  return ['admin', 'business'].includes(role);
}

export const isCategoryUser = role => {
  return ['admin', 'business', 'category'].includes(role);
}
