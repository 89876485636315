import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useImmer } from "use-immer";
import { useDebouncedCallback } from "use-debounce";
import Bugsnag from "@bugsnag/js";
import PLink from "../../common/Navigation/PLink";
import { api } from "../../utils/api";
import LEAD_FIELDS, { DEFAULT_VISIBLE_FIELDS } from "../../utils/leadFields";

import useLeads from "../../hooks/useLeads";
import useCategories from "../../hooks/useCategories";
import useMutateLead from "../../hooks/useMutateLead";
import useBusinesses from "../../hooks/useBusinesses";
import useGlobalParams from "../../hooks/useGlobalParams";
import useUserCategoryColumns from "../../hooks/useUserCategoryColumns";
import useStatuses from "../../hooks/useStatuses";

import Toast from "../../common/Toast/Toast";
import LeadsMode from "../../common/Navigation/LeadsMode";
import FilterDropdown from "./FilterDropdown";
import SortableColumn from "./SortableColumn";
import LeadRow from "./LeadRow";
import FilterBar from "./FilterBar";
import LeadDetailsModal from "./LeadDetailsModal";
import EditColumnsDropdown from "./EditColumnsDropdown";
import ExportModal from "./ExportModal";
import ImportLeadsModal from "../../common/Leads/ImportLeadsModal";
import LeadImportNotifications from "../../common/Leads/LeadImportNotifications";
import Form from 'react-bootstrap/Form';
import Dropdown from "react-bootstrap/Dropdown";

import FilterIcon from "../../assets/icons/FilterIcon";
import LeadsIcon from "../../assets/icons/LeadsIcon";
import TrashIcon from "../../assets/icons/TrashIcon";
import { ReactComponent as ExportIcon } from "../../assets/icons_svg/export.svg";
import { ReactComponent as ImportIcon } from "../../assets/icons_svg/import.svg";
import { ReactComponent as LightningIcon } from "../../assets/icons_svg/lightning.svg";

import './LeadsListView.scss';
import '../../common/Dropdown.scss';

const BULK_ACTION_WIDTH = 40;
const DELETE_WIDTH = 40;

export default function LeadsListView() {

  const [selectedLeads, setSelectedLeads] = useImmer(() => new Set());
  const [sortBy, setSortBy] = useState('date_added');
  const [sortDir, setSortDir] = useState('desc');
  const [filters, setFilters] = useImmer([]);
  const [showDetails, setShowDetails] = useState(false);
  const [editingId, setEditingId] = useState(null);
  const [undoLeads, setUndoLeads] = useImmer();
  const [showDeleteLead, setShowDeleteLead] = useState(false);
  const [showUserColumns, setShowUserColumns] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const [downloadLink, setDownloadLink] = useState(null);
  const [downloadError, setDownloadError] = useState(false);

  const { categoryId, organizationId, businessId } = useGlobalParams();

  const showFilters = filters.length > 0;

  const ordering = (sortDir === 'desc' ? '-' : '') + sortBy;

  const { data: businesses } = useBusinesses({ organization_id: organizationId, staleTime: 1000 });
  const { data: leads, isLoading, /*isFetching,*/ isError } = useLeads({ staleTime: 5000, ordering, filters, categoryId, keepPreviousData: true });
  const { data: categories } = useCategories({ businessId, staleTime: 10000, keepPreviousData: true });
  const { data: categoryColumns } = useUserCategoryColumns({ categoryId });
  const { data: statuses } = useStatuses({ categoryId });

  const { deleteMutation, bulkDeleteMutation, undoDeleteMutation } = useMutateLead();

  const business = businesses?.find(business => business.id === businessId);
  const category = categories?.find(category => category.id === categoryId);

  const columns = useMemo(() => {
    return categoryColumns?.[0]?.columns.filter(item => item.visible).map(item => item.column) || DEFAULT_VISIBLE_FIELDS;
  }, [categoryColumns]);

  const minTableWidth = useMemo(() => {
    return columns.reduce((acc, column) => acc + LEAD_FIELDS[column].columnWidth, 0);
  }, [columns]);

  const orderedStatuses = useMemo(() => {
    return statuses?.sort((a, b) => a.order_index - b.order_index);
  }, [statuses]);

  useEffect(() => {
    setFilters([]);
  }, [categoryId, setFilters]);

  // const leadQuery = useLeads({ staleTime: 5000 });

  // let leads, isLoading, isError, error;
  // console.log('leadQuery:', leadQuery);


  const handleToggleLead = (lead, e) => {
    setSelectedLeads(draft => {
      e.target.checked ? draft.add(lead.id) : draft.delete(lead.id)
    });
  }

  const handleBulkDelete = () => {
    bulkDeleteMutation.mutate(Array.from(selectedLeads.keys()));

    setSelectedLeads(new Set());
  }

  const handleUndo = () => {
    if (undoLeads?.length === 1) {
      undoDeleteMutation.mutate(undoLeads[0]);
    }

    hideDeleteToast.flush();
    setUndoLeads(null);
  }

  const showDeleteToast = () => {
    setShowDeleteLead(true);
    hideDeleteToast();
  }

  const hideDeleteToast = useDebouncedCallback(() => {
    setShowDeleteLead(false);
  }, 5000);

  const handleDelete = lead => {
    // de-select the lead if it was selected:
    selectedLeads.has(lead.id) && setSelectedLeads(draft => {
      draft.delete(lead.id)
    });

    showDeleteToast();

  showDetails && setShowDetails(false);
    
    const index = leads.findIndex(l => l.id === lead.id);
    setUndoLeads([{ lead, index }]);
    
    deleteMutation.mutate(lead.id);
  }

  const handleChangeSort = (by, dir) => {
    setSortBy(by);
    setSortDir(dir);
  }

  const onSelectAll = e => {
    if (e.target.checked) {
      const allLeads = new Set(leads.map(lead => lead.id));
      setSelectedLeads(allLeads);
    }
    else {
      setSelectedLeads(new Set());
    }
  }

  const onClickRow = id => {
    setEditingId(id);
    setShowDetails(true);
  }

  const onHideDetails = () => {
    setEditingId(null);
    setShowDetails(false);
  }

  const exportLeads = async (selected = false) => {
    setDownloadLink(null);
    setDownloadError(null);
    setShowExport(true);

    try {
      const { data } = await api.post(`/leads/export/?category_id=${categoryId}`, {
        lead_ids: selected ? Array.from(selectedLeads) : undefined,
      });
  
      setDownloadLink('data:text/plain;charset=utf-8,' + encodeURIComponent(data));
    }
    catch (e) {
      Bugsnag.notify(e);
      setDownloadError(true);
    }
  }

  const allLeadsSelected = useMemo(() => {
    return leads?.length > 0 && !leads.some(lead => !selectedLeads.has(lead.id));
  }, [leads, selectedLeads]);

  const columnFlex = column => LEAD_FIELDS[column].columnWidth * 100 / minTableWidth;

  const renderContent = () => {
    if (isLoading) {
      return <pre>Loading...</pre>
    }
  
    else if (isError) {
      return <pre>Error fetching leads :S </pre>
    }

    return (
      <>
        <div className='content'>
          <div className='controls'>
            <div className='title-category'>
              <div className='leads-icon'><LeadsIcon /></div>
              <div>
                <h2>{ business?.name }</h2>
                <div className='breadcrumbs'>
                  <div className={classNames('text-content', { 'all-leads': categories && categoryId })}>
                    <div>All Leads</div>
                    <div>Leads » { category?.name }</div>
                  </div>
                </div>
              </div>
            </div>
            <div className='actions'>
              <button className='main-actions' onClick={() => setShowDetails(true)}>＋ Add</button>

              <FilterDropdown toggle={filters.length > 0 ? ActiveFilterToggle : FilterToggle} filters={filters} setFilters={setFilters} align='end'>
                <FilterIcon /><span>Filters</span>
              </FilterDropdown>

              <Dropdown className='__dropdown actions-dropdown' align='end'>
                <Dropdown.Toggle as={selectedLeads?.size > 0 ? ActiveFilterToggle : FilterToggle}>
                  <LightningIcon />
                  <span>Actions</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setShowImport(true)}><ImportIcon className='import-icon' />Import Leads</Dropdown.Item>
                  <Dropdown.Item onClick={() => exportLeads()}><ExportIcon className='export-icon' />Export All</Dropdown.Item>

                  { selectedLeads.size > 0 && (
                    <>
                      <Dropdown.Divider />
                      <Dropdown.Item onClick={() => exportLeads(true)}><ExportIcon className='export-icon' />Export Selected</Dropdown.Item>
                      <Dropdown.Item onClick={handleBulkDelete}><TrashIcon className='trash-icon' /> Delete Selected</Dropdown.Item>
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>

              {/* } */}
            </div>
          </div>
        </div>

        { showFilters && <FilterBar filters={filters} setFilters={setFilters} removeFromTop={showUserColumns} /> }
        
        <div className='content leads-table'>
          <div className='table-overflow' style={{ width: minTableWidth + BULK_ACTION_WIDTH + DELETE_WIDTH }}>
            <div className='table-head'>
              <div className='bulk-action'>
                <Form className="bulk-toggle-form">
                  <Form.Check type='checkbox' className='bulk-toggle' checked={allLeadsSelected} onChange={onSelectAll} />
                  { !allLeadsSelected && selectedLeads.size > 0 && <div className='partial-selection' /> }
                </Form>
              </div>
              { columns?.map(column => (
                <div key={column} className={column} style={{ flex: columnFlex(column) }}>
                  <SortableColumn type={column} selected={sortBy === column} sortDir={sortDir} onChangeSort={handleChangeSort} />
                </div>
              ))}
              <div className='delete-lead'><EditColumnsDropdown categoryId={categoryId} show={showUserColumns} setShow={setShowUserColumns} /></div>
            </div>
            <div className='table-body'>
              {/* { leads?.pages[0].map(lead => <LeadRow key={lead.id} lead={lead} selected={selectedLeads.has(lead.id)} onToggleChange={e => handleToggleLead(lead, e)} />) }  */}
              { leads?.map(lead => (
                <LeadRow key={lead.id} organizationId={organizationId} categoryId={categoryId} statuses={orderedStatuses} lead={lead} columns={columns} minTableWidth={minTableWidth} selected={selectedLeads.has(lead.id)} onToggleChange={e => handleToggleLead(lead, e)} onClickRow={onClickRow} onDeleteLead={() => handleDelete(lead)} />
              ))}

              { leads?.length === 0 && (
                <div className="empty-leads">
                  { showFilters ? (
                    <>
                      <h3>No leads match your filter</h3>
                      <p><button className='clear-filter' onClick={() => setFilters([])}>Clear filter</button> to view all your leads</p> 
                    </>
                  ) : (
                  <>
                    <h3>No leads in this category yet</h3>
                    <p><button onClick={() => setShowDetails(true)}>Add a new lead</button>, <button onClick={() => setShowImport(true)}>import from CSV</button>, or <PLink to={`/leads/settings/${categoryId}`}>create an integration.</PLink></p>
                  </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <LeadDetailsModal organizationId={organizationId} categoryId={categoryId} defaultStatus={category?.default_status_id} show={showDetails} lead={leads.find(lead => lead.id === editingId)} onHide={onHideDetails} onDelete={() => handleDelete(leads.find(lead => lead.id === editingId))} />
        <ImportLeadsModal show={showImport} onHide={() => setShowImport(false)} />
        
        <ExportModal show={showExport} onHide={() => setShowExport(false)} downloadLink={downloadLink} error={downloadError} category={category} />

        <Toast show={showDeleteLead} action='Undo' onHide={() => hideDeleteToast.flush()} onActionClick={handleUndo}>
          Lead moved to trash
        </Toast>
      </>
    )
  }

  return (
    <section className='__leads-list'>
      <LeadImportNotifications />
      <LeadsMode />
      { renderContent() }
    </section>
  )
}

const FilterToggle = React.forwardRef(({ children, onClick, ...props }, ref) => (
  <button onClick={onClick} ref={ref} {...props} className='main-actions'>
    { children }
  </button>
));

const ActiveFilterToggle = React.forwardRef(({ children, onClick, ...props }, ref) => (
  <button onClick={onClick} ref={ref} {...props} className='main-actions active'>
    { children }
  </button>
));
