import Modal from 'react-bootstrap/Modal';
import AddLeadStatus from './AddLeadStatus';

export default function AddLeadStatusModal({ show, onHide, statuses, onCreateSuccess }) {

  if (!statuses) {
    return;
  }
  
  return (
    <Modal show={show} className='__modal __add-lead-status-modal' centered backdrop='static' onHide={onHide}>
      <Modal.Header closeButton><h2>Add New Status</h2></Modal.Header>
      <Modal.Body>
        <AddLeadStatus show={show} statuses={statuses} fromModal onCreateSuccess={onCreateSuccess} onHide={onHide} />
      </Modal.Body>
    </Modal>
  )
}
