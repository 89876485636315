import { useEffect, useMemo } from 'react';
import { useImmer } from 'use-immer';
import useGlobalParams from '../../hooks/useGlobalParams';
import useUsers from '../../hooks/useUsers';

import UserAvatar from '../../common/User/UserAvatar';
import Button from '../../common/Button/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import './LeadDistributionModal.scss';
import classNames from 'classnames';

const intRegex = RegExp(/^(\+|-)?\d+$/);
const floatRegex = RegExp(/^(\+|-)?\d*\.\d*$/);

const ERROR_MESSAGES = {
  empty : "Enter a number between 0 and 100",
  float  : "Enter a whole number (no decimals allowed)",
  NaN   : "Enter a valid number",
  large : "Enter a number between 0 and 100",
}

const invalidDistribution = distribution => {

  if (typeof distribution === 'string') {
    distribution = distribution.trim();
  }

  const intValue = parseInt(distribution, 10);

  if (distribution.length === 0) {
    return false;
  }

  if (floatRegex.test(distribution)) {
    return 'float';
  }

  if (!intRegex.test(distribution)) {
    return 'NaN';
  }

  else if (intValue < 0 || intValue > 100) {
    return 'large';
  }
}

export default function LeadDistributionModal({ show, onHide, onSave, customDistribution, category, ...props }) {

  const [distribution, setDistribution] = useImmer();
  const [invalidValues, setInvalidValues] = useImmer({});

  const { organizationId, categoryId } = useGlobalParams();
  const { data: users } = useUsers({ organizationId, categoryId });

  useEffect(() => {
    if (show) {
      setDistribution(category?.lead_distribution_map || customDistribution || {});
      setInvalidValues({}); 
    }
  }, [show, category, customDistribution, setDistribution, setInvalidValues, users]);

  const totalWeight = useMemo(() => {
    const getNumber = n => invalidDistribution(n) ? 0 : parseInt(n, 10);

    return (distribution && Object.values(distribution).reduce((a, b) => a + (getNumber(b, 10) || 0), 0)) || 0;
  }, [distribution]);

  const onChangeInput = (id, value) => {
    setDistribution(draft => {
      draft[id] = value;
    });

    setInvalidValues(draft => {
      draft[id] = invalidDistribution(value);
    });
  }

  if (!users || !distribution) {
    return;
  }

  const renderExplanation = () => {
    if (totalWeight === 'NaN') {
      return;
    }

    else if (totalWeight <= 100) {
      return <>Remaining percentage:<span className='number'> {100 - totalWeight}%</span></>
    }
    
    else {
      return <div className='error'>
        Please make sure all percentages add up to 100%
      </div>
    }
  }

  const disableForm = totalWeight !== 100 || Object.values(invalidValues).find(invalid => ['NaN', 'float'].includes(invalid));

  const renderTable = () => (
    <>
      <p className='intro'>Incoming leads will be assigned to users according to following percentages. <a href="#SUP">Learn more</a></p>
      <table>
        <thead>
          <tr>
            <th className='user'>User</th>
            <th className='distribution'>Percentage</th>
            <th className='error'></th>
          </tr>
        </thead>
        <tbody>
          { users.map(user => (
            <tr key={user.id}>
              <td className='user'>
                <UserAvatar user={user} showName />
              </td>
              <td className='distribution'>
                <div className={classNames('percentage-input', { 'input-error': invalidValues[user.id] })}>
                  <Form.Control type='text' value={distribution[user.id] || ''} onChange={e => onChangeInput(user.id, e.target.value)} />
                  <span>%</span>
                </div>
                {/* <ReactSlider className='percentage-slider' value={user.percentage} onChange={value => onChangeValue(user.id, value)} /> */}
              </td>
              <td className='error'>{ invalidValues[user.id] && ERROR_MESSAGES[invalidValues[user.id]] }</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className='remaining-percentage'>
        { renderExplanation() }
      </div>
    </>
  );

  const renderNoUsers = () => (
    <div className='no-users'>This category has no users assigned to it. Add users in the <a href="#wip">Organization Page</a></div>
  )

  return (
    <Modal className='__lead-distribution-modal __modal' show={show} onHide={onHide} centered size='lg' backdrop='static' {...props}>
      <Modal.Header closeButton onHide={onHide}><h2>Custom Lead Distribution</h2></Modal.Header>
      <Modal.Body>
        { users.length > 0 ? renderTable() : renderNoUsers() }
      </Modal.Body>
      <Modal.Footer>
        <Button className='cancel' onClick={onHide}>Cancel</Button>
        <Button className='confirm' disabled={disableForm} onClick={() => onSave(distribution)}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
}
