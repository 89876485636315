import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import classNames from "classnames";
import { handleNetworkError } from "../../utils/api";
import LEAD_STATUS_COLORS from "../../utils/leadStatusColors";

import useMutateStatus from '../../hooks/useMutateStatus';
import useGlobalParams from '../../hooks/useGlobalParams';

import Form from 'react-bootstrap/Form';
import Button from "../../common/Button/Button";

import './AddLeadStatus.scss';

export default function AddLeadStatus({ onHide, show, statuses, onCreateSuccess, fromModal }) {

  const { categoryId } = useGlobalParams();

  const initialColor = () => {
    const colorValues = Object.values(LEAD_STATUS_COLORS).map(color => color.toLowerCase());
    const statusColors = statuses.map(status => status.color.toLowerCase());
    const unusedColor = colorValues.find(color => !statusColors.includes(color));

    return unusedColor || colorValues[0];
  }

  const { createStatus } = useMutateStatus();

  const [createName, setCreateName] = useState('');
  const [createDescription, setCreateDescription] = useState(undefined);
  const [createColor, setCreateColor] = useState(initialColor);
  const [creating, setCreating] = useState(false);
  const [createErrors, setCreateErrors] = useState(false);
  const [createValidated, setCreateValidated] = useState(false);

  const addRef = useRef();
  const nameInputRef = useRef();

  useLayoutEffect(() => {
    if (!fromModal) {
      const rect = addRef.current.getBoundingClientRect();
  
      if (rect.bottom > window.innerHeight || rect.top < 0) {
        addRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [fromModal]);

  useEffect(() => {
    if (show) {
      nameInputRef.current.focus();
    }
  }, [show]);

  const onCreate = e => {
    e.preventDefault();

    setCreating(true);
    setCreateErrors(false);
    setCreateValidated(false);

    const lastIndex = statuses[statuses.length - 1]?.order_index || 0;

    const data = {
      category_id : categoryId,
      name        : createName,
      description : createDescription || undefined,
      color       : createColor,
      order_index : lastIndex + 1,
    }

    const onSettled = () => {
      setCreating(false);
    }

    const onSuccess = newStatus => {
      onCreateSuccess(newStatus.id);
      onHide();
    }

    const onError = e => {
      handleNetworkError({ e, setErrors: setCreateErrors, setValidated: setCreateValidated });
    }

    createStatus.mutate({ data }, { onSettled, onSuccess, onError });
  }

  return (
    <div className="__add-lead-status" ref={addRef}>
      <Form onSubmit={onCreate} noValidate validated={createValidated && !createErrors}>
        <div className="name-description">
          <Form.Group className="name">
            <Form.Label>Name</Form.Label>
            <Form.Control ref={nameInputRef} autoFocus value={createName} maxLength={64} onChange={e => setCreateName(e.target.value)} isValid={createValidated && !createErrors?.name} isInvalid={createErrors?.name} />
            { createErrors?.name?.map(error => <Form.Control.Feedback key={error} type='invalid'>{ error }</Form.Control.Feedback> )}
          </Form.Group>

          <Form.Group className="description">
            <Form.Label>Description</Form.Label>
            <Form.Control placeholder="(optional)" value={createDescription || ''} maxLength={64} onChange={e => setCreateDescription(e.target.value)} isValid={createValidated && !createErrors?.description} isInvalid={createErrors?.description} />
            { createErrors?.description?.map(error => <Form.Control.Feedback key={error} type='invalid'>{ error }</Form.Control.Feedback> )}
          </Form.Group>
        </div>
        <div className="color-actions">
          <div className="colors-container">
            <div className="label">Color</div>
            { Object.values(LEAD_STATUS_COLORS).map((color, index) => (
              <button key={color} type='button' onClick={() => setCreateColor(color.toLowerCase())} className={classNames("color", { selected: color.toLowerCase() === createColor.toLowerCase(), middle: index === Object.values(LEAD_STATUS_COLORS).length / 2 })} style={{ backgroundColor: `#${color}` }} />
            ))}
          </div>
          <div className="actions">
            <Button disabled={creating} className='cancel' type='button' onClick={onHide}>Cancel</Button>
            <Button loading={creating} type='submit'>Create</Button>
          </div>
        </div>

        { createErrors?.non_field_errors?.map(error => <div className="request-error" key={error}>{ error }</div> )}
      </Form>
    </div>
  )
}
