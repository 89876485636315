import useCategories from "../../hooks/useCategories";

import Form from 'react-bootstrap/Form';
import useMutateRole from "../../hooks/useMutateRole";
import useGlobalParams from "../../hooks/useGlobalParams";

export default function RoleCategories({ businessId, role }) {

  const { organizationId } = useGlobalParams();
  const { data: categories } = useCategories({ businessId });
  const { updateUserRole } = useMutateRole({ organizationId });

  if (!categories) {
    return;
  }

  const onToggleCategory = (id, checked) => {
    const category_ids = [ ...role.category_ids ];
    const business_ids = [ ...role.business_ids ];

    if (checked) {
      category_ids.push(id);
      !business_ids.includes(businessId) && business_ids.push(businessId);
    }
    else {
      const index = category_ids.findIndex(categoryId => categoryId === id);
      category_ids.splice(index, 1);
    }

    console.log(category_ids);

    const data = {
      business_ids: business_ids,
      category_ids
    };

    updateUserRole.mutate({ data, id: role.id });
  }

  console.log('role:', role)

  return (
    <div>
      { categories.map(category => (
        <Form.Check key={category.id} checked={role.category_ids.includes(category.id)} label={category.name} onChange={e => onToggleCategory(category.id, e.target.checked)} />
      ))}
    </div>
  );
}
