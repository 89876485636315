import React, { useContext, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

import { ReactComponent as DevWidgetIcon } from '../../assets/icons_svg/dev_widget.svg';
import './DevWidget.scss';
import AppContext from '../../utils/AppContext';
import { Link } from 'react-router-dom';


export default function DevWidget() {
  const [state, dispatch] = useContext(AppContext);
  const [showOutlines, setShowOutlines] = useState(false);

  const { colorTheme, currentUser, localSettings } = state;

  const showUserId = localStorage.getItem('pl__dev_show_user_id');

  const changeTheme = value => {
    dispatch({ type: 'colorTheme/set', payload: value });
  }

  const toggleOutlines = () => {
    document.body.classList.toggle('__dev-outlines');
    setShowOutlines(!showOutlines);
  }

  const toggleShowUserId = () => {
    showUserId ? localStorage.removeItem('pl__dev_show_user_id') : localStorage.setItem('pl__dev_show_user_id', 'true');
    window.location.reload();
    console.log('refresh...')
  }

  const clearLocalSettings = () => {
    dispatch({ type: 'localSettings/clearAll' });
  }

  const logLocalSettings = () => {
    console.log(JSON.stringify(localSettings, null, 2));
  }

  return (
    <aside className='__dev-widget'>
      <Dropdown>
        <Dropdown.Toggle as={Toggle}>
          <DevWidgetIcon />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Header>{ currentUser?.email }</Dropdown.Header>
          <Dropdown.Item as={Link} to='/dev/user-colors'>User Colors</Dropdown.Item>
          <Dropdown.Divider />

          <Dropdown.Header>Color Theme</Dropdown.Header>
          <Dropdown.Item active={colorTheme === 'light'} onClick={() => changeTheme('light')}>Light Mode</Dropdown.Item>
          <Dropdown.Item active={colorTheme === 'dark'} onClick={() => changeTheme('dark')}>Dark Mode</Dropdown.Item>
          <Dropdown.Item active={colorTheme === 'auto'} onClick={() => changeTheme('auto')}>Auto</Dropdown.Item>
          
          <Dropdown.Divider />
          <Dropdown.Item onClick={toggleOutlines}>{ showOutlines ? 'Hide' : 'Show' } outlines</Dropdown.Item>
          <Dropdown.Item onClick={toggleShowUserId}>{ showUserId === 'true' ? 'Hide' : 'Show' } User ID</Dropdown.Item>
          
          <Dropdown.Divider />
          <Dropdown.Item onClick={clearLocalSettings}>Clear Local Settings</Dropdown.Item>
          <Dropdown.Item onClick={logLocalSettings}>Log Local Settings</Dropdown.Item>
          <Dropdown.Item as={Link} to='/logout'>Logout</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </aside>
  )
}

const Toggle = React.forwardRef(({ children, onClick, ...props }, ref) => (
  <button onClick={onClick} ref={ref} {...props} className='__dev-widget-toggle'>
    { children }
  </button>
));
