import { useMemo } from 'react';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { DateTime } from 'luxon';

import Button from '../../common/Button/Button';

import { ReactComponent as CheckMarkIcon } from "../../assets/icons_svg/check-mark-outline.svg";
import './ExportModal.scss';

const normalizeFileName = string => (
  string.trim().normalize("NFD").replace(/\W+/g, '_')
);

export default function ExportModal({ show, onHide, error, downloadLink, category }) {
  const categoryName = category?.name;

  const fileName = useMemo(() => {
    if (downloadLink) {
      const date = DateTime.now();
      return normalizeFileName(categoryName) + '_' + date.toFormat('yyyy-mm-dd') + '.csv';
    }
  }, [downloadLink, categoryName]);

  const renderContent = () => {
    if (error) {
      return (
        <>
          <h2 className='failed'>Export failed</h2>
          <p className='export-ready'>There was a problem with your export. Try again or <a href="#WIP">contact us</a> if the error persists</p>
          <Button variant='link' onClick={onHide}>Close</Button>
        </>
      );
    }
    else if (downloadLink) {
      return (
        <>
        <div className='checkmark'><CheckMarkIcon /></div>
        <p className='export-ready'>Your export file is ready!</p>
        <p><Button as='a' href={downloadLink} variant='primary' download={fileName}>Download CSV</Button></p>
        <Button variant='link' onClick={onHide}>Close</Button>
        </>
      );
    }
    else {
      return (
        <>
          <div className='spinner'><Spinner /></div>
          <p>Preparing CSV export...</p>
        </>
      );
    }
  }


  return (
    <Modal className='__modal __export-modal' show={show} onHide={(downloadLink || error) && onHide} backdrop='static'>
      <Modal.Header closeButton={downloadLink || error}><h2>Export Leads</h2></Modal.Header>
      <Modal.Body>
        { renderContent() }
      </Modal.Body>
    </Modal>
  );
}
