import { useContext, useMemo } from "react";
import AppContext from "../../utils/AppContext";
import { useLocation, useParams } from "react-router-dom";
import classNames from "classnames";
import PLink from "./PLink";
import { isCategoryUser } from "../../utils/userUtils";

import KanbanIcon from "../../assets/icons/KanbanIcon";
import ListIcon from "../../assets/icons/ListIcon";
import { ReactComponent as SettingsIcon } from "../../assets/icons_svg/gear.svg";

import './LeadsMode.scss';

export default function LeadsMode() {
  const [state, dispatch] = useContext(AppContext);
  const { categoryId } = useParams();
  const location = useLocation();

  const { localSettings, currentUserRole } = state;

  const showSettings = useMemo(() => {
    return (isCategoryUser(currentUserRole))
  }, [currentUserRole]);

  const getCurrentPage = () => {
    if (location.pathname.startsWith('/leads/settings/')) {
      return 'settings';
    }
    
    return localSettings.leads_mode === 'kanban' ? 'kanban' : 'list';
  }

  const currentPage = getCurrentPage();

  const setMode = value => {
    dispatch({ type: 'localSettings/edit', payload: { setting: 'leads_mode', value } });
  }

  const toggleKanban = () => currentPage !== 'kanban' && setMode('kanban');
  const toggleList = () => currentPage !== 'list' && setMode('list');

  const ButtonLink = currentPage === 'settings' ? PLink : 'button';

  return (
    <div className='__leads-mode'>
      <div className='container-edge-left' />

      <div className='main-container'>
        <ButtonLink onClick={toggleKanban} className={classNames({ active: currentPage === 'kanban' })} to={`/leads/${categoryId}`}><KanbanIcon /> Kanban</ButtonLink>
        <ButtonLink onClick={toggleList} className={classNames({ active: currentPage === 'list' })} to={`/leads/${categoryId}`}><ListIcon />List View</ButtonLink>
        { showSettings && <PLink to={`/leads/settings/${categoryId}`} className={classNames({ active: currentPage === 'settings' })}><SettingsIcon /> Settings</PLink> }
      </div>
      
      <div className='container-edge-right' />
    </div>
  );
}
