import { useEffect, useState } from "react";
import { handleNetworkError } from "../../utils/api";
import { FIELD_MAX_LENGTH } from "../../utils/leadFields";

import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';

import PencilIcon from "../../assets/icons/PencilIcon";
import CheckIcon from "../../assets/icons/CheckIcon";
import XIcon from "../../assets/icons/XIcon";

import './EditableField.scss';

export default function EditableField({ id, field, initialValue, customValue, mutation, mutationData, updateName, onEditSucces, addLongName, maxLength }) {
  const [value, setValue] = useState('');
  const [editing, setEditing] = useState(false);
  const [errors, setErrors] = useState(null);
  // const [validated, setValidated] = useState(false);

  const { [updateName]: update } = mutation(mutationData);

  const handleCancel = () => {
    setValue(initialValue);
    setEditing(false);
    setErrors(null);
    // setValidated(false);
  }

  const handleSave = e => {
    e.preventDefault();

    // the dual first / last name inputs need to be split in two:
    const data = field === 'first_last_name' ? { first_name: value[0], last_name: value[1] } : { [field]: value };

    const onError = e => {
      handleNetworkError({ e, setErrors, /*setValidated*/ });
      setEditing(true);
    }

    const onSuccess = e => {
      onEditSucces?.();
      setErrors(null);
      // setValidated(false);
    }

    update.mutate({ id, data }, { onError, onSuccess });
    setEditing(false);
  }

  useEffect(() => {
    // if the initialValue is changed by another mutation, this should
    // be reflected in the label or input to avoid desynced data:
    setValue(initialValue);
  }, [initialValue]);
  
  const onChangeValue = value => {
    if (customValue) {
      value = customValue(value);
    }

    setValue(value);
  }

  const inputType = field === 'email' ? 'email' : 'text';

  const renderInputField = () => {
    if (field === 'first_last_name') {
      return (
        <>
          <Form.Control type={inputType} value={value[0]} onChange={e => setValue([e.target.value, value[1]])} autoFocus maxLength={FIELD_MAX_LENGTH.first_name} />
          <div className='field-divider'> / </div>
          <Form.Control type={inputType} value={value[1]} onChange={e => setValue([value[0], e.target.value])} autoFocus maxLength={FIELD_MAX_LENGTH.last_name} />
        </>
      );
    }
    else {
      return (
        <div className="input-container">
          <Form.Control type={inputType} value={value || ''} onChange={e => onChangeValue(e.target.value)} autoFocus maxLength={maxLength || 254} isInvalid={errors?.[field]} />
          { errors?.[field]?.map(error => <Form.Control.Feedback tooltip key={error} type="invalid">{ error }</Form.Control.Feedback> )}
        </div>
      );
    }
  }

  if (editing) {
    return (
      <Form onSubmit={handleSave} className='__editable-field editing' data-interactive>
        { field === 'full_name' && <div className='field-label'>Full Name</div>}
        { field === 'first_last_name' && (
          <>
            <div className='field-label'>First Name</div>
            <div className='field-label last-field'>Last Name</div>
          </>
        )}
        { renderInputField() }
        <div className='actions'>
          <button type='submit'><CheckIcon className='save' /></button>
          <button type="button"><XIcon className='close' onClick={handleCancel} /></button>
        </div>
      </Form>
    );
  }

  const hasValue = Array.isArray(value) ? (value[0]?.trim() || value[1]?.trim()) : value?.trim();

  return (
    <form className='__editable-field'>
      { hasValue
      ? <div className='field'>{ Array.isArray(value) ? `${value[0]} ${value[1]}` : value }</div> 
      : <Button variant='link' onClick={() => setEditing(true)} className='empty-field-add'>+ Add { addLongName && field }</Button>
      }
      { update.isLoading
      ? <Spinner className='saving' size="sm" />
      : hasValue && <button className='edit' onClick={() => setEditing(true)}><PencilIcon /></button>
      }
    </form>
  );
}
