import React from "react";
import classNames from "classnames";
import Dropdown from 'react-bootstrap/Dropdown';
import ChevronIcon from "../../assets/icons/ChevronIcon";

import './LeadDropdown.scss';

export default function LeadStatusDropdown({ lead, statuses, selectedStatusId, onChange, type, showCreate, onCreate, placeholder, fixed }) {
  
  if (!statuses) {
    return <div>...</div>;
  }

  const selectedStatus = statuses?.find(status => status.id === selectedStatusId || status.id === lead?.status_id);
  
  return (
    <Dropdown className={classNames('__lead-dropdown', { [`__lead-dropdown-${type}`]: type })}>
      <Dropdown.Toggle as={Toggle} className='lead-toggle'>
        <div className="toggle-overflow">
          { selectedStatus && <div className='square' style={{ backgroundColor: `#${selectedStatus.color}` }} /> }
          <div className={classNames('label', { unassigned: !selectedStatus })}>{ selectedStatus?.name || placeholder || 'No status set' }</div>
          <ChevronIcon className='chevron' />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu renderOnMount={fixed} popperConfig={fixed && { strategy: 'fixed' }}>
        { statuses.length === 0 && (
          <Dropdown.ItemText className="empty-text">(No statuses found)</Dropdown.ItemText>
        )}
        { statuses.map(status => (
          <Dropdown.Item key={status.id} as='button' active={status.id === selectedStatus?.id} onClick={() => onChange(status.id)}>
            <div className='square' style={{ backgroundColor: `#${status.color}` }} />
            <div className='status-label'>{ status.name }</div>
          </Dropdown.Item>
        ))}
        { showCreate && (
          <>
            <Dropdown.Divider />
            <Dropdown.Item as='button' onClick={() => onCreate()} className="create-new">＋ Create New Status</Dropdown.Item>
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}

const Toggle = React.forwardRef(({ children, onClick, ...props }, ref) => (
  <button onClick={onClick} ref={ref} {...props}>
    { children }
  </button>
));
