import { useContext, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import AppContext from "../../utils/AppContext";
import { api } from "../../utils/api";

import Button from "../../common/Button/Button";
import UserAvatar from "../../common/User/UserAvatar";

import { ReactComponent as ErrorIcon } from "../../assets/icons_svg/error-lined.svg";

import './UserInvitation.scss';

export default function UserInvitation() {
  const [state] = useContext(AppContext);
  const [searchParams] = useSearchParams();
  const [invitation, setInvitation] = useState(false);
  const navigate = useNavigate();
  const token = searchParams.get('token');

  const redirectURL = useMemo(() => {
    return encodeURIComponent(window.location.pathname) + encodeURIComponent(window.location.search)
  }, []);

  useEffect(() => {
    setTimeout(() => setInvitation(true), 2000);
  }, []);

  const { currentUser } = state;

  const onAcceptInvite = async () => {
    const { data } = await api.post('/user-roles/invitations/accept/', {
      invitation_token: token,
    });

    console.log('data:', data)

    navigate('/');
  }

  const renderInvitation = () => {
    if (!invitation) {
      return (
        <>
          <div className="loading-avatar" />
          <div className="loading-text" />
          <div className="loading-text" />
        </>
      );
    }

    if (!token) {
      return (
        <>
          <ErrorIcon className="error-icon" />
          <h3>This invitation link is invalid or has expired.</h3>
          <p className="description">Get in touch with an administrator of the organization to reset or create a new invitation.</p>
        </>
      );
    }
  
    return (
      <>
        <UserAvatar user={{ id: 104, first_name: "Bailalo" }} />
        <h3>You have been invited to join the organization<br /><strong>Bailalo Dance Studio</strong></h3>
        { currentUser ? <Button variant='primary' onClick={onAcceptInvite}>Accept Invitation</Button> : <Link className="__button __button-primary" to={`/login?redirect_to=${redirectURL}`}>Log in to continue</Link> }
        { currentUser
        ? <div className='logged-as'>Logged in as: { currentUser.email }</div>
        : <p className='create-account'><Link to={`/signup?redirect_to=${redirectURL}`}>Create an account</Link></p>
        }

      </>
    )
  }
  
  return (
    <section className='__user-invitation'>
      <h1>Precious Leads</h1>
      <div className="invitation-content">
        { renderInvitation() }
      </div>
    </section>
  );
}
