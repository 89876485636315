import { useCallback, useMemo, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { DateTime } from "luxon";
import classNames from "classnames";
import { USER_ROLES, userFullName } from "../../utils/userUtils";
import PLink from "../../common/Navigation/PLink";

import useGlobalParams from "../../hooks/useGlobalParams";
import useUsers from "../../hooks/useUsers"
import useUserRoles from "../../hooks/useUserRoles";

import UserRoleModal from "./UserRoleModal";

import { ReactComponent as SearchIcon } from "../../assets/icons_svg/search-icon.svg";
import ArrowIcon from "../../assets/icons/ArrowIcon";
import './Users.scss';

const COLUMNS = {
  name       : "Name",
  edit       : "Edit",
  email      : "Email",
  role       : "Role",
  date_added : "Date Added",
}

const getFullName = (user, role) => (
  user ? userFullName(user) : role.invitation_email.split('@')[0]
);

// lowercase, trim whitespace and convert any multiple whitespaces to single ones,
// normalize to replace diacritics and accents such as ñ -> n and á -> a
const normalizeString = string => (
  string.toLowerCase().trim().replace(/\s+/g, ' ').normalize("NFD").replace(/[\u0300-\u036f]/g, '')
);

export default function Users() {  
  const [sortBy, setSortBy] = useState('date_added');
  const [sortDir, setSortDir] = useState('asc');
  const [search, setSearch] = useState('');
  const [searchRoles, setSearchRoles] = useState(null);
  const [showUserModal, setShowUserModal] = useState(false);
  const [editingRole, setEditingRole] = useState(null);
  
  const { organizationId } = useGlobalParams();
  
  const { data: users } = useUsers({ organizationId });
  const { data: userRoles } = useUserRoles({ organizationId });

  const getUser = useCallback(role => {
    return users.find(user => user.id === role.user_id);
  }, [users]);

  const onChangeSearch = value => {
    setSearch(value);
    
    const text = normalizeString(value);
    debouncedSearch(text);
    text.length === 0 && debouncedSearch.flush();
  }

  const debouncedSearch = useDebouncedCallback(text => {
    if (!text) {
      setSearchRoles(null);
    }
    else {
      // NOTE: this is a shallow copy of userRoles
      setSearchRoles(userRoles.filter(role => {
        const user = getUser(role);

        const fullName = normalizeString(getFullName(user, role));
        const email = user?.email || role.invitation_email;
        
        return fullName.includes(text) || email.includes(text);
      })); 
    }
  }, 300, { maxWait: 1000 }); 

  const sortedRoles = useMemo(() => {
    const orderDirection = (sortDir === 'asc' ? 1 : -1);

    switch (sortBy) {
      case 'date_added':
        return sortDir === 'asc' ? userRoles : userRoles.toReversed();

      case 'name':
        return userRoles.sort((roleA, roleB) => {
          const nameA = getFullName(getUser(roleA), roleA);
          const nameB = getFullName(getUser(roleB), roleB);

          return nameA.localeCompare(nameB) * orderDirection;
        });

      case 'email': {
        return userRoles.sort((roleA, roleB) => {
          const userA = getUser(roleA);
          const userB = getUser(roleB);

          const emailA = userA?.email || roleA.invitation_email;
          const emailB = userB?.email || roleB.invitation_email;

          return emailA.localeCompare(emailB) * orderDirection;
        });
      }

      case 'role': {
        return userRoles.sort((roleA, roleB) => {
          const roleTypeA = Object.keys(USER_ROLES).findIndex(role => role === roleA.role);
          const roleTypeB = Object.keys(USER_ROLES).findIndex(role => role === roleB.role);

          return (roleTypeA - roleTypeB) * orderDirection;
        });
      }

      // pending invitations are shown in the 'date added' column:
      // case 'status': {
      //   return userRoles.sort((roleA, roleB) => {
      //     const inviteA = roleA.user_id ? 1 : 0;
      //     const inviteB = roleB.user_id ? 1 : 0;

      //     return (inviteA - inviteB) * orderDirection;
      //   });
      // }

      default:
        return userRoles;
    }

  }, [getUser, userRoles, sortBy, sortDir]);

  const renderRow = (role, user) => (
    <tr key={role.id} role='button'>
      <td>{ getFullName(user, role) }</td>
      <td><PLink to={`/user-roles/${role.id}`}>EDIT</PLink></td>
      <td>{ user?.email || role.invitation_email }</td>
      <td>{ USER_ROLES[role.role] }</td>
      <td className={classNames({"invitation-pending": !role.user_id })}>
        { !role.user_id ? 'Invitation Pending' : DateTime.fromISO(role.date_added).toLocaleString(DateTime.DATE_MED) }
      </td>
    </tr>
  )

  const changeSort = by => {
    if (by === sortBy) {
      setSortDir(sortDir === 'asc' ? 'desc' : 'asc');
    }
    else {
      setSortBy(by);
      setSortDir('asc');
    }
  }

  const onClickAddUser = () => {
    setEditingRole(null);
    setShowUserModal(true);
  }

  const renderNoMatches = () => (
    <div className="no-matches">
      No users match your search. <button onClick={() => onChangeSearch('')}>Clear search</button>
    </div>
  )

  const renderContent = () => (
    <div className="content">
      <div className="match-clear">
        { searchRoles?.length > 0
        ? <>Showing {searchRoles.length} out of {userRoles.length} { userRoles.length === 1 ? 'user' : 'users'}. <button onClick={() => onChangeSearch('')}>Clear search</button></>
        : '\u00A0'
        }
      </div>
      <table>
        <thead>
          <tr>
            { Object.entries(COLUMNS).map(([ key, value]) => (
              <th key={key} className={key}>
                <button className={classNames("sort-by", { selected: key === sortBy })} onClick={() => changeSort(key)}>
                  <span>{ value }</span>
                  { key === sortBy && <span className='arrow'><ArrowIcon className={classNames(sortDir)} /></span> }
                </button>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          { (searchRoles || sortedRoles).map(role => renderRow(role, users.find(user => user.id === role.user_id))) }
        </tbody>
      </table>
    </div>
  );

  const editModalRole = editingRole && userRoles.find(role => role.id === editingRole);
  const editModalUser = editingRole && users.find(user => user.id === editModalRole?.user_id);

  return (
    <section className="__users">
      <div className="title-actions">
        <h2>Users & Roles</h2>

        <div className="search">
          <SearchIcon />
          <input type="text" placeholder="Search by name or email" autoCorrect="false" autoCapitalize="off" autoComplete="false" spellCheck="false" value={search} onChange={e => onChangeSearch(e.target.value)} />
          { search.length > 0 && <button onClick={() => onChangeSearch('')} className="clear">×</button> }
        </div>

        <button className="add-user" onClick={onClickAddUser}>+ Add User</button>
      </div>

      { users && userRoles && (searchRoles?.length === 0 ? renderNoMatches() : renderContent() )}

      <UserRoleModal role={editModalRole} user={editModalUser} organizationId={organizationId} show={showUserModal} onHide={() => setShowUserModal(false)} />
    </section>
  )
}
