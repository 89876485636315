import { useEffect, useState } from 'react';
import classNames from 'classnames';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from '../Button/Button';

import './ConfirmationModal.scss';

export default function ConfirmationModal({ title, show, children, cancelText, inputConfirm, confirmText, dontShowAgain, onHide, onConfirm, ...props }) {
  const [confirmValue, setConfirmValue] = useState('');

  useEffect(() => {
    if (show) {
      setConfirmValue('');
    }
  }, [show]);

  const confirmDisabled = inputConfirm && !['yes', '"yes"'].includes(confirmValue.trim());

  const onCofirmForm = e => {
    e.preventDefault();
    onConfirm();
  }

  return (
    <Modal className={classNames("__modal", "__confirmation-modal", {"__confirmation-modal-dont-show": dontShowAgain })} onHide={onHide} show={show} {...props} backdrop='static' centered>
      <Modal.Header onHide={onHide} closeButton><h2>{ title }</h2></Modal.Header>
      <Form onSubmit={onCofirmForm}>
        <Modal.Body>
          { children }

          { inputConfirm && (
            <>
              <p>To confirm, type "yes" in the field below.</p>
              <Form.Control type='text' autoFocus value={confirmValue} onChange={e => setConfirmValue(e.target.value)} />
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          { dontShowAgain && <Form.Check label={ typeof dontShowAgain === 'string' ? dontShowAgain : "Don't show again" } id='__confirmation-dont-show' /> }
          <Button type='button' className='cancel' onClick={onHide}>{ cancelText || 'Cancel' }</Button>
          <Button type='submit' autoFocus={!inputConfirm} className='confirm' disabled={confirmDisabled}>{ confirmText || 'Confirm' }</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
